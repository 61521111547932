<template>
  <div>
    <header>
      <v-row>
        <v-col cols="12" md="2" sm="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="datesFormatted"
                label="Fecha desde / hasta"
                readonly
                v-on="on"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" locale="es-ar" range></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" sm="4" class="filtro-sede">
          <v-select
            :items="sedes"
            item-text="nombre"
            item-value="idObraSocial"
            prepend-icon="mdi-calendar-multiple"
            v-model="sede"
            label="Sede"
            dense
            @change="getConsultasPorPaciente"
          ></v-select>
        </v-col>
        <v-col class="reload-btn">
          <v-btn color="primary" @click="getConsultasPorPaciente"
            >Recargar</v-btn
          >
        </v-col>
      </v-row>
    </header>
    <main>
      <v-row>
        <v-col cols="12" md="5" sm="4">
          <v-data-table
            :headers="headers"
            :items="listaPacientes"
            class="elevation-1"
            locale="es-ar"
            item-key="idPaciente"
            :items-per-page="10"
            ref="myTable"
            :disable-sort="true"
            :hide-default-footer="false"
            no-data-text="Sin datos en las fechas seleccionadas"
            :footer-props="{
              itemsPerPageText: 'Pacientes por página',
            }"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </main>
  </div>
</template>
<script>
import { getData } from "../../../api/apiService";
import reportesMixin from "../../../mixins/reportesMixin";
import moment from "moment";

export default {
  name: "ConsultasPorPaciente",
  mixins: [reportesMixin],
  data() {
    return {
      menu2: false,
      dates: [],
      datesFormatted: "",
      sedes: [],
      sede: 0,
      headers: [
        { text: "Empleado / Paciente", value: "paciente" },
        { text: "Cantidad Turnos Totales", value: "cantTurnosTotales" },
        { text: "Cantidad Turnos Atendidos", value: "cantAtendidos" },
        {
          text: "Cantidad Turnos Sin Atender",
          value: "cantTurnosSinAtender",
        },
      ],
      listaPacientes: [],
    };
  },
  watch: {
    dates(val) {
      if (val.length === 2) {
        moment.locale("fr");
        this.datesFormatted =
          moment(val[0]).format("L") + " - " + moment(val[1]).format("L");
        this.menu2 = false;
        this.getConsultasPorPaciente();
      }
    },
  },
  methods: {
    setInitialData() {
      const primerDiaMes = moment().startOf("month").format("YYYY-MM-DD");
      const diaActual = moment().format("YYYY-MM-DD");
      this.dates = [primerDiaMes, diaActual];
    },
    async getConsultasPorPaciente() {
      try {
        const params = {
          idSede: this.sede,
          fechaDesde: this.dates[0],
          fechaHasta: this.dates[1],
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        const pacientesData = await getData(
          "/Dashboard/ConsultasPorPaciente",
          params
        );
        this.listaPacientes = pacientesData;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  async created() {
    this.$loading(true);
    this.setInitialData();
    await this.getSedes();
    await this.getConsultasPorPaciente();
    this.$loading(false);
  },
};
</script>
<style>
.filtro-sede {
  margin-top: 17px;
}
.reload-btn {
  margin-left: auto;
  flex-grow: 0;
}
</style>
