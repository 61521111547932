import { getData } from "../api/apiService";

export default {
  data() {
    return {
      sedes: [],
      sede: 0,
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    setInitialData() {
      let month = this.formatDate(this.toISOLocal(new Date())).substring(3, 5);
      if (month == 0) month = 12;
      let year = this.formatDate(this.toISOLocal(new Date())).substring(6, 10);
      if (month.toString().length == 1) month = "0" + month;
      this.mesFormatted = month + "/" + year;
      this.maxMes = year + "-" + month;
      this.mes = year + "-" + month;
    },
    async getSedes() {
      try {
        const params = {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        const obrasSocialesData = await getData(
          "/ObraSocial/GetObraSocialActivasDeInst",
          params
        );
        this.sedes = obrasSocialesData;
        if (this.sedes.length > 1)
          this.sedes.unshift({
            idObraSocial: 0,
            nombre: "TODAS",
          });
        this.sede = this.sedes[0].idObraSocial;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
