<template>
  <div>
    <header>
      <v-row>
        <v-col cols="12" md="2" sm="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="datesFormatted"
                label="Fecha desde / hasta"
                readonly
                v-on="on"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" locale="es-ar" range></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" sm="4" class="filtro-sede">
          <v-select
            :items="sedes"
            item-text="nombre"
            item-value="idObraSocial"
            prepend-icon="mdi-calendar-multiple"
            v-model="sede"
            label="Sede"
            dense
            @change="getPacientesAtendidos"
          ></v-select>
        </v-col>
        <v-col class="reload-btn">
          <v-btn color="primary" @click="getPacientesAtendidos">Recargar</v-btn>
        </v-col>
      </v-row>
    </header>
    <main>
      <v-row>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              color="cyan"
              dark
              class="mt-4 mx-auto"
              height="130"
              width="350"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: start;
                  justify-content: space-between;
                  padding: 0.6rem;
                  padding-top: 0.7rem;
                "
              >
                <div style="display: flex; align-items: center">
                  <v-icon>mdi-calendar</v-icon>
                  <h4 class="ml-2">TURNOS RESERVADOS TOTALES</h4>
                </div>
                <h1 style="font-size: 40px; margin-top: 0.5rem">
                  {{ cantTurnosTotales }}
                </h1>
              </div>
              <div>
                <v-icon class="ml-3 mb-2">mdi-chart-bar</v-icon>
              </div>
            </v-card>
          </template>
          <span>Todos los turnos reservados.</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              color="cyan"
              dark
              class="mt-4 mx-auto"
              height="130"
              width="350"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: start;
                  justify-content: space-between;
                  padding: 0.6rem;
                  padding-top: 0.7rem;
                "
              >
                <div style="display: flex; align-items: center">
                  <v-icon>mdi-calendar</v-icon>
                  <h4 class="ml-2">TURNOS ATENDIDOS</h4>
                </div>
                <h1 style="font-size: 40px; margin-top: 0.5rem">
                  {{ cantTurnosAtendidos }}
                </h1>
              </div>
              <div>
                <v-icon class="ml-3 mb-2">mdi-chart-bar</v-icon>
              </div>
            </v-card>
          </template>
          <span
            >Solo pacientes con motivo de consulta cargado en el día del
            turno.</span
          >
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              color="cyan"
              dark
              class="mt-4 mx-auto"
              height="130"
              width="350"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  align-items: start;
                  justify-content: space-between;
                  padding: 0.6rem;
                  padding-top: 0.7rem;
                "
              >
                <div style="display: flex; align-items: center">
                  <v-icon>mdi-calendar</v-icon>
                  <h4 class="ml-2">TURNOS SIN ATENDER</h4>
                </div>
                <h1 style="font-size: 40px; margin-top: 0.5rem">
                  {{ cantTurnosSinAtender }}
                </h1>
              </div>
              <div>
                <v-icon class="ml-3 mb-2">mdi-chart-bar</v-icon>
              </div>
            </v-card>
          </template>
          <span
            >Solo pacientes con motivo de consulta cargado en el día del
            turno.</span
          >
        </v-tooltip>
      </v-row>
    </main>
  </div>
</template>
<script>
import reportesMixin from "../../../mixins/reportesMixin";
import { getData } from "../../../api/apiService";
import moment from "moment";

export default {
  name: "ConsultasPorPaciente",
  mixins: [reportesMixin],
  data() {
    return {
      menu2: false,
      dates: [],
      datesFormatted: "",
      cantTurnosTotales: 0,
      cantTurnosAtendidos: 0,
      cantTurnosSinAtender: 0,
    };
  },
  watch: {
    dates(val) {
      if (val.length === 2) {
        moment.locale("fr");
        this.datesFormatted =
          moment(val[0]).format("L") + " - " + moment(val[1]).format("L");
        this.menu2 = false;
        this.getPacientesAtendidos();
      }
    },
  },
  methods: {
    setInitialData() {
      const primerDiaMes = moment().startOf("month").format("YYYY-MM-DD");
      const diaActual = moment().format("YYYY-MM-DD");
      this.dates = [primerDiaMes, diaActual];
    },
    async getPacientesAtendidos() {
      try {
        const params = {
          idSede: this.sede,
          fechaDesde: this.dates[0],
          fechaHasta: this.dates[1],
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        const pacientesData = await getData(
          "/Dashboard/PacientesAtendidos",
          params
        );
        this.cantTurnosTotales = pacientesData.cantTurnosTotales;
        this.cantTurnosSinAtender = pacientesData.cantTurnosSinAtender;
        this.cantTurnosAtendidos = pacientesData.cantTurnosAtendidos;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  async created() {
    this.$loading(true);
    this.setInitialData();
    await this.getSedes();
    await this.getPacientesAtendidos();
    this.$loading(false);
  },
};
</script>
<style>
.filtro-sede {
  margin-top: 17px;
}
</style>
