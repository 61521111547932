<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="salir">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> Reportes </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-expansion-panels v-model="state" accordion class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Pacientes atendidos
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><PacientesAtendidos />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Consultas por paciente
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ConsultasPorPaciente />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Motivos de consulta
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MotivosConsulta />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn color="error" @click.stop="salir">Salir</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import MotivosConsulta from "./Empresas/MotivosConsulta.vue";
  import ConsultasPorPaciente from "./Empresas/ConsultasPorPaciente.vue";
  import PacientesAtendidos from "./Empresas/PacientesAtendidos.vue";
  export default {
    name: "Reportes",
    components: { MotivosConsulta, ConsultasPorPaciente, PacientesAtendidos },
    data() {
      return {
        state: null,
      };
    },
    methods: {
      salir() {
        this.$router.push({ name: "Home" });
      },
    },
  };
</script>
